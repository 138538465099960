export default API => ({
    // 商品列表
    getGoodsList( {data,params} ) {
        return API({ 
            method:'post',
            url: `/saas/v1/renshe/goods/distributionGoodsList`,
            data,
            params
        });
    },
    // 筛选信息
    getfilterInfo( {data,params} ) {
        return API({ 
            url: `/saas/v1/renshe/goods/filterInfo`,
            data,
            params
        });
    },
    // 获取职业工种简介文档
    getVocationByCode( {data,params} ) {
        return API({ 
            url: `/dynamic/v1/maas/getVocationByCode`,
            data,
            params
        });
    },
    getDocumentAccessToken({data,params}) {
        return API({ 
            url: `/resource/v1/resAccessToken/getDocumentToken`,
            data,
            params
        })
    },
    
    
})