

export default API => ({
    // 获取标准列表
    getStandardList( data ) {
        return API({ 
            method:'post',
            url: `/modular/admin/v1/block/getArticleInfo`,
            data
        });
    },
    // 首页商品列表
    distributionGoodsList( data, params ) {
        return API({ 
            method:'post',
            url: `/saas/v1/renshe/goods/distributionGoodsList?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
            data
        });
    },
    // 首页筛选信息
    filterInfo( params ) {
        return API({ 
            method:'get',
            url: `/saas/v1/renshe/goods/filterInfo?branchId=${params.branchId}&centerType=${params.centerType}&tagFlag=${params.tagFlag}`,
        });
    },
    // 首页滚动下单信息
    findOrderUser( branchId ) {
        return API({ 
            method:'get',
            url: `/saas/v1/order/findOrderUser?branchId=${branchId}`,
        });
    },
    // 点击商品列表的商品调用此接口
    distributeAndGetGoods( params ) {
        return API({ 
            method:'post',
            url: `/saas/v1/renshe/goods/distributeAndGetGoods?distributionGoodsId=${params}`,
        });
    },
    getWorkUrl() {
        return API({ 
            method:'get',
            url: `/dynamic/v1/maas/getWorkUrl`,
        });
    },
    
    getImmPreview(params) {
        return API({ 
            method:'get',
            url: `/dynamic/v1/maas/getImmPreview`,
            params
        });
    },
    // 文档，图片url
    getImageOrDocUrls(id) {
        return API({ url: `/resource/v1/resAccessToken/getDocumentToken?resId=${id}`})
    },
    canPromote(params) {
        return API({ //简介
            url: '/saas/v1/renshe/goods/canPromote',
            params
        });
    },


})