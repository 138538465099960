<template>
	<div class="top-account">
		<template v-if="!token">
			<div class="loginBtn" @click="goLogin">学员登录</div>
		</template>

		<template v-else>
			<el-dropdown class="user-dropdown" @visible-change="toggleUser">
				<div class="user-account">
					<img class="user-img" v-if="subUserInfo.headImage" :src="subUserInfo.headImage" alt="" />
					<img class="user-img" v-else src="@assets/userL.png" alt="" />
					<p class="txt">
						{{ subUserInfo.name || '--' }}
					</p>
					<i :class="[{ 'el-icon-arrow-down': !showUser }, { 'el-icon-arrow-up': showUser }]"></i>
				</div>
				<el-dropdown-menu slot="dropdown" class="account-dropdown-menu">
					<el-dropdown-item @click.native="setRealName">
						<ul>
							<li>
								{{ subUserInfo.name || '--' }}
								<span
									v-if="typeof isRealName === 'boolean'"
									:class="{
										isRealName: true,
										RealNamed: isRealName,
										Unnamed: !isRealName,
									}"
								>
									<svg-icon icon-class="yishimingicon" v-if="isRealName"></svg-icon>
									{{ isRealName ? '已实名' : '未实名' }}
								</span>
							</li>
							<li>{{ getTel }}</li>
						</ul>
					</el-dropdown-item>
					<el-dropdown-item divided @click.native="showPersonalCenter">
						<ul>
							<li>
								<span>个人中心</span>
							</li>
						</ul>
					</el-dropdown-item>
					<el-dropdown-item v-if="branchList.length > 0" divided @click.native="showBranchList">
						<ul>
							<li>切换机构</li>
							<li>{{ branchName || '--' }}</li>
						</ul>
					</el-dropdown-item>
                    <el-dropdown-item v-else divided>
						<ul>
							<li>所在机构</li>
							<li>{{ branchName || '--' }}</li>
						</ul>
					</el-dropdown-item>
					<el-dropdown-item divided @click.native="signOut">
						<ul>
							<li>退出登录</li>
						</ul>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</template>
		<el-dialog title="" :visible.sync="isRealNameShow" :modal="false" width="464px" center>
			<div class="account-dialog">
				<p class="branchTitle">实名认证</p>
				<p class="qrcodetitle ct">请使用微信或手机浏览器扫描下方二维码，完成实名认证.</p>
				<div class="qrcode">
					<img src="@assets/qrcodeXia.png" class="xia" alt="" />
					<img src="@assets/qrcodeShang.png" class="shang" alt="" />
					<div id="qrcode"></div>
				</div>
				<p class="qrcodetitle ct">个人隐私信息安全保障中</p>
			</div>
		</el-dialog>

		<!-- 学员加入机构列表 -->
		<el-dialog title="" :visible.sync="branchListShow" :modal="false" width="400px" center>
			<div class="account-dialog">
				<p class="branchTitle">切换机构</p>
				<ul class="ct">
					<li v-for="item in branchList" :key="item.id" @click="primaryBranch(item)" :class="{ primary: primary === item.branch, mt10: true }">
						<span v-show="branch === item.branch" class="current">当前</span>{{ item.name }}
					</li>
				</ul>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="setBranch">选好了</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import MD5 from 'js-md5'
import QRCode from 'qrcodejs2'
import axios from 'axios'
import config from '@/config/url'
export default {
	data() {
		return {
			token:'',
			domainUrl: null,
			showUser: false,
			branchList: [],
			branchName: '',
			branch: localStorage.getItem('branch'),
			isRealName: null,
			isRealNameShow: false,
			QRCode: null,
			branchListShow: false,
			primary: '',
			selectedBranch: null,
		}
	},
	computed: {
		getTel() {
			const reg = /^(\d{3})\d{4}(\d{4})$/
			if (this.userInfo.phone) {
				return this.userInfo.phone.replace(reg, '$1****$2')
			} else {
				return '--'
			}
		},
		userInfo() {
			return this.$store.state.userInfo
		},
		// 不同机构
		subUserInfo() {
			return this.$store.state.subUserInfo
		},
	},
	watch:{
		'$store.state.token':{
			handler(v){
				this.token = v;
				if (v) {
					this.postIsRealNameAuthentication()
					this.getFindUserBranchList()
					this.branchName = localStorage.getItem('branchName')
					this.branch = localStorage.getItem('branch')
				}
			},
			immediate:true,
			deep:true,
		}
	},
	methods: {
		goLogin() {
			this.$router.push('/login')
		},
		showPersonalCenter() {
			this.$router.push({
				path: '/personalCenter',
			})
		},
		toggleUser(show) {
			this.showUser = show
		},
		getFindUserBranchList() {
			this.$api.usercenter.getFindUserBranchList({}).then((res) => {
				if (res.data && res.data.length) {
					this.branchList = res.data
				}
			})
		},
		showBranchList() {
			this.branchListShow = !this.branchListShow
			this.primary = ''
		},
		primaryBranch(item) {
			this.selectedBranch = item
			this.primary = item.branch;
		},
		setBranch() {
			if (!this.primary) {
				this.showBranchList()
				return
			}
			// 获取切换机构的域名
			const token = localStorage.getItem('token')
			const { organ, actor, branch, name } = this.selectedBranch
			// window.location.href = `http://12639648.channel.testing3.wdeduc.com/distributionHomePage?token=${token}&organ=${organ}&actor=${actor}&branch=${branch}`
			axios({
				url: config.API + '/modular/admin/v1/saasBranchDomain/0',
				headers: {
					token,
					organ,
					actor,
					branch,
				},
			}).then((res) => {
				let data = res.data

				if (data.success && data.data && data.data.length) {
					let domain = data.data.find((item) => {
						return item.branchDomain && !item.branchDomain.includes('h5')
					})
					if (domain) {
						let protocol = 'https://'
						if (domain.branchDomain.includes('testing3')) {
							protocol = 'http://'
						}
						domain = protocol + domain.branchDomain

						console.log('domain--------------------', domain)

						window.location.href = `${domain}/distributionHomePage?token=${token}&organ=${organ}&actor=${actor}&branch=${branch}&name=${name}`
					}
				} else {
					this.$message.error(data.data.message)
				}
			})
		},
		setRealName() {
			if (this.isRealName) return
			this.isRealNameShow = !this.isRealNameShow
			this.$nextTick(() => {
				this.qrcode()
			})
		},
		async getSaasBranchDomain(){
            await this.$api.rensheUsercenter.getSaasBranchDomain(localStorage.getItem('branchId')).then((res) => {
                if (res.success && res.data && res.data.length) {
                    let h5domain = ''
                    res.data.forEach((item) => {
                        if (item.branchDomain) {
                        if (item.pcOrH5 === 1) {
                            h5domain = item.branchDomain;
                            this.h5domain = h5domain || '';
                        } 
                        }
                    })
                }
            })
            localStorage.setItem('rensheH5Domain',this.h5domain)
            return this.h5domain
        },
		async qrcode() {
			if (this.QRCode) return
			let domainArr = localStorage.getItem('domain')
			let domainUrl = domainArr && JSON.parse(domainArr).find((item) => item.frontend_template_id == 4).domain
			if(localStorage.getItem('theme') == 'renshe'){ //人社定制
                if(localStorage.getItem('rensheH5Domain')){
                    domainUrl = localStorage.getItem('rensheH5Domain');
                }else{
                    domainUrl = await this.getSaasBranchDomain();
                }
            }
			this.QRCode = new QRCode('qrcode', {
				width: 154,
				height: 154, // 高度
				text: `https://${domainUrl || 'hrss-stu.testing2.wdeduc.com'}/login?phone=${this.userInfo.phone}`, // 二维码内容
				display: 'none',
			})
		},
		postIsRealNameAuthentication() {
			this.$api.monitor.postIsRealNameAuthentication({}).then((res) => {
				const m = `${localStorage.getItem('token')}true`
				this.isRealName = res.data === MD5(m)

				localStorage.setItem('isRealName', this.isRealName)
			})
		},
		async signOut() {
			const params = { token: this.token }
			await this.$api.usercenter.postAccounLogout({ params })
			this.$message.success('退出成功')
			this.$store.commit('setToken', '')
			const saasId = localStorage.getItem('saasId')
			sessionStorage.clear()
			localStorage.clear();
			localStorage.setItem('saasId', saasId)
			this.$router.push('/distributionHomePage')
		},
	},
}
</script>
<style lang="stylus" scoped>
@import '../../assets/css/account.styl';
</style>
