const UrlName = '/modular/admin/v1'
import URL from '../../config/url';



export default API => ({
    getConfiguration({ params, data }) {
        return API({ url: `${UrlName}/configurations/`, params, data });
    },
    //业务端获取PageJson
    getPageJson(){
      const params = {};
      params.domain = URL.confUrl;
      return API({ 
        url: `${UrlName}/block/getPageConfig`, 
        params: params,
        method: 'POST',
      });
    },
})
 