export default [
	{
		path: '/login',
		name: 'login',
		meta: { login: false, title: '登录' },
		component: () => import(/* webpackChunkName: 'entrances' */ './page/login.vue'),
	},
	{
		path: '/register',
		name: 'register',
		meta: { login: false, title: '注册' },
		component: () => import(/* webpackChunkName: 'entrances' */ './page/register.vue'),
	},
]
