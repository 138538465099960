export default [
    {
        path: '/mall/standardList',
        name: 'standardList',
        meta: {login: false},
        component: () => import(/* webpackChunkName: 'gongxin' */ './page/standard-list.vue'),
    },
    {
        path: '/mall/goodsDetail',
        name: 'goodsDetail',
        meta: {login: false},
        component: () => import(/* webpackChunkName: 'gongxin' */ './page/courseGoodsDetail.vue'),
    },
    {
        path: '/distributionHomePage',
        name: 'distributionHomePage',
        meta: {login: false,customerBox:true},
        component: () => import(/* webpackChunkName: 'gongxin' */ './page/distributionHomePage.vue'),
    },
    {
        path: '/mall/pdfDetail',
        name: 'pdfDetail',
        meta: {login: false},
        component: () => import(/* webpackChunkName: 'gongxin' */ './page/seePdfDetail.vue')
    }
]