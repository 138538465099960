// import _ from 'lodash'
import api from '../api'
// import i18n from '../locale'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import conf from '../config/url'
import getLanguageData from './locale'

Vue.use(VueI18n)
// 自动根据浏览器系统语言设置语言
const navLang = navigator.language
// localStorage.setItem('current_language',navLang)

// let language = window.localStorage.current_language || navLang
// 调用模块化配置接口
async function getConfiguration() {
  await api.configuration.getConfiguration(conf.confUrl)
    .then(async(res) => {
      let frontendConfig = res.data.frontendConfig;
      frontendConfig.map(item=>{
        console.log(item.key,'wwqeqwqwwqeqwewq');
        if(item.key == 'is_i18n'){
          console.log(item.key);
          localStorage.setItem('is_i18n',item.value)
        }
      })
      getLanguageData();
    })
}
getConfiguration()
// getLanguageData()
let zhObj = {}
let enObj = {}
let frObj = {}

zhObj = localStorage.getItem(`language_pc_zh-CN_data`) ? JSON.parse(localStorage.getItem(`language_pc_zh-CN_data`)) : {}
enObj = localStorage.getItem(`language_pc_en-US_data`) ? JSON.parse(localStorage.getItem(`language_pc_en-US_data`)) : {}
frObj = localStorage.getItem(`language_pc_fr-FR_data`) ? JSON.parse(localStorage.getItem(`language_pc_fr-FR_data`)) : {}
const zhLogin = Object.assign(require('./language').default.zhLogin,zhObj)
const enLogin = Object.assign(require('./language').default.enLogin,enObj)
const frLogin = Object.assign(require('./language').default.frLogin,frObj)

const message = {
  'zh':zhLogin,
	'zh-CN': zhLogin,
  'zh-cn': zhLogin,
  'en':enLogin,
	'en-US': enLogin,
  'fr-FR': frLogin,
  'fr':frLogin,
}
console.log('messa,', message)
console.log('11111',localStorage.getItem('current_language'));
export default new VueI18n({
	locale: localStorage.getItem('current_language') ? localStorage.getItem('current_language') : navLang,
	messages: message,
  // fallbackLocale:'fr-FR',
	silentTranslationWarn: true,
})
