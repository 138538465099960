export default [
  {
      path: '/institutionsIn',
      name: 'institutionsIn',
      meta: {login: false},
      component: () => import(/* webpackChunkName: 'institutionsIn' */ './page/institutionsIn.vue'),
  },
  {
      path: '/organ-settled',
      name: 'OrganSettled',
      meta: {login: false},
      component: () => import(/* webpackChunkName: 'OrganSettled' */ './components/trainInstitutionsInDialog.vue'),
  },
  {
    path: '/resource-settled',
    name: 'ResourceSettled',
    meta: {login: false},
    component: () => import(/* webpackChunkName: 'ResourceSettled' */ './components/resourceInstitutionsInDialog.vue'),
  }
]