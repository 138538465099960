import Vue from 'vue';
import './libs/element';
import './assets/theme/index.css'
import App from './App.vue';
import router from './router';
import store from './store';
import config from './config';
import api from './api';
import i18n from './locale';
import Templates from './libs/template'
import Tools from '@stroll/tools'
import './styl/app.styl';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
config.productionTip = process.env.NODE_ENV === 'development';
import 'promise-polyfill/src/polyfill';
import dateFormat from './libs/dateFormat';// 当前时间转换
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import sensors from './utils/sensors'
Vue.prototype.$sensors = sensors
import question from '@wd/question';
import '@wd/question/lib/question.css';
import SDK from '@wd/lowcode-pc'
import 'wdn-pc/src/libs/saas.js'
import './assets/icons'

Vue.use(question)
/**
 * @description 全局注册应用配置
 */
Vue.use(Templates)
Vue.use(VueVideoPlayer)
Vue.prototype.$config = config;
Vue.prototype.$tools = Tools
Vue.prototype.$api = api;
Vue.prototype.$dateFormat = dateFormat;
Vue.prototype.$t = function(str, fallbackStr) {
  // use `$te` and `$t
  return i18n.t && i18n.te
    ? i18n.te(str)
      ? i18n.t(str)
      : fallbackStr?.text || str
    : fallbackStr?.text || str;
};

//新加配置代码
Vue.prototype.$store = store;
const TcPlayer = window.TcPlayer
Vue.prototype.TcPlayer = TcPlayer

const BrowserLogger = require('alife-logger');
// BrowserLogger.singleton(conf) conf传入config配置。
// eslint-disable-next-line no-unused-vars
const bl = BrowserLogger.singleton({
    pid: 'g2t7afypx3@a71a3d04b8450ea',
    // 设定日志上传地址：
    // 部署新加坡地域可设为`https://arms-retcode-sg.aliyuncs.com/r.png?`。
    // 部署美西地域可设为`http://arms-us-west-1.console.aliyun.com/r.png?`。
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    // enableLinkTrace:true,
    // enableApiCors: true,
    // 其他config配置。
});

Promise.all([SDK.setLayoutToRouter(router,store)]).then(()=>{
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#wdc-app')
}).catch(()=>{
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#wdc-app')
})
