export default API => ({
  getCmsContent(data) {
    return API({
      url: `/cms/v1/special/getSpecialContent`,
      method: 'post',
      params: data
    })
  },
  // 公告公示
  getNotice(params) {
    return API({
      url: `/cms/v1/notice/findNotice`,
      method: 'get',
      params
    })
  },
  // 是否是伟东或者平行机构
  branchIdIsWDBranch(params) {
    return API({
      url: `/cms/v1/notice/branchIdIsWDBranch`,
      method: 'get',
      params
    })
  },
});