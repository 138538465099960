<template>
	<div id="wdc-app">
		<router-view v-if="excludeNavPage.includes($route.name)"></router-view>
		<template v-else>
			<rensheNav v-if="isrenshe" />
			<jigouNav v-else />
			<div ref="main" class="main" style="padding-top: 72px">
				<router-view :key="key" />
			</div>
		</template>
		<div class="rightNav" v-show="$route.meta.customerBox && (isrenshe || wdBranch)">
      <div class="text tel-con">
          <div class="tel-box">
            <p>400-8555-686转1</p>
          </div>
          <p class="icon-circle"><svg-icon icon-class="side-tel"></svg-icon></p>
          <p class="txt">电话咨询</p>
        </div>
      <div class="text" @click="qimoChat()">
        <p class="icon-circle"><svg-icon icon-class="side-customer"></svg-icon></p>
        <p class="txt">立即咨询</p>
      </div>
      <div class="mobile">
        <div class="code-box">
          <div class="qrcode-box">
            <div id="qrcode" ref="qrcode"></div>
          </div>
          <div class="text">
            <p class="icon-circle"><svg-icon icon-class="side-phone"></svg-icon></p>
            <p class="txt">移动端</p>
          </div>
        </div>
      </div>
      <div class="text" @click="goTop()">
        <p class="icon-circle"><svg-icon icon-class="side-back"></svg-icon></p>
        <p class="txt">回到顶部</p>
      </div>
    </div>
	</div>
</template>

<script>
import rensheNav from './view/head/rensheNav'
import jigouNav from './view/head/jigouNav'
import isrenshe from '@/utils/judgeDomain'
import QRCode from "qrcodejs2";

export default {
	data() {
		return {
      wdBranch:window.location.hostname.includes('18188548.jiafu.ethrss.cn'), //是否伟东参培机构
			isrenshe: true,
			excludeNavPage: [
				'login',
				'register',
				'error',
				'video',
				'teacherLive',
				'studentSee',
				'forget',
				'training_detail',
				'testPaper',
				'livePlayback',
				'personnel',
				'screen',
				'service-agreement',
				'privacy',
				'unfind'
			],
		}
	},
	computed: {
		key() {
			return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
		},
	},
	components: {
		rensheNav,
		jigouNav,
	},
	async created() {
    this.isrenshe = isrenshe;
  },
	async mounted() {
		this.qrcodeimg();
	},
	methods: {
		qimoChat(){
      window.qimoChatClick()
    },
		//回到顶部
    goTop(){
			window.scrollTo({
				top:0,
				behavior:'smooth'
			})
		},
		//生成二维码
    qrcodeimg () {
        let envArr = ['testing1', 'testing2', 'testing3', 'fat', 'uat'];
        let env = window.location.port ? 'testing3' : '';
        envArr.forEach(d => {
            if (location.hostname.includes(d)) {env = d}
        })
				let url = null 
				let hostname = window.location.hostname
				const strPo = window.location.hostname.split('.')[0]
				if(this.isrenshe){ //平台
					if(hostname.includes('jiafu') && !env){
						url = `${location.protocol}//jiafu-m.ethrss.cn`;
					}else{
						url = env ?  `${location.protocol}//ethrss-h5.${env}.wdeduc.com` : `${location.protocol}//ethrss-h5.wdeduc.com`;
					}
				}else{ //机构
					if(hostname.includes('jiafu') && !env){
						url = `${location.protocol}//${strPo}.jiafu-m.ethrss.cn`;
					}else{
						url = env ?  `${location.protocol}//${strPo}.channel-h5.${env}.wdeduc.com` : `${location.protocol}//${strPo}.channel-h5.wdeduc.com`;
					}
				}
				console.log(url,'路径');
        // if(hostname.includes('ethrss') && !hostname.includes('jiafu') ) {
        //   url = env ?  `${location.protocol}//${env.includes('testing')? 'wdn-rensheh5' :'ethrss-h5'}.${env}.wdeduc.com` : `${location.protocol}//ethrss-h5.wdeduc.com`;
        // } else if( hostname.includes('mohrss')) {
        //   url = env ?  `${location.protocol}//${env.includes('testing')? 'wdn-rensheh5' :'mohrss-h5'}.${env}.wdeduc.com` : `${location.protocol}//mohrss-h5.wdeduc.com`;
        // }else if(hostname.includes('jiafu')){
        //    url = env ?  `${location.protocol}//${env.includes('testing')? 'wdn-rensheh5' :'jiafu-m'}.${env}.ethrss.cn` : `${location.protocol}//jiafu-m.ethrss.cn`;
				// }
				new QRCode(this.$refs.qrcode, {
						text:url,
						width: 110,
						height: 110,
						// colorDark: '#ffffff', // 二维码颜色
						colorLight: '#ffffff', // 二维码背景色
						correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
				}, 100)
    },
	},
}
</script>
<style lang="stylus" scoped>
.rightNav{
  position: fixed;
  right: 24px;
  bottom: 32px;
  z-index: 999;
  background: #316FFF;
  border-radius: 40px;
  color: #fff;
  padding-bottom:8px;
  .tel-box{
    width: 180px;
    height: 56px;
    position: absolute;
    right:88px;
    line-height: 56px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.09);
    opacity: 0;
    border-radius: 6px;
    &:after{
      position: absolute;
      content: '';
      border-top: 4px transparent dashed;
      border-left: 4px #fff solid;
      border-bottom: 4px transparent dashed;
      border-right: 4px transparent dashed;
      right: -8px;
      top: 22px;
    }
    p{
      font-size: 16px;
      color: #333;
    }
  }
  .qrcode-box{
    width: 144px;
    height: 144px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 200px;
    right: 96px;
    // display: none;
    opacity: 0;
    border-radius: 6px;
    &:after{
      position: absolute;
      content: '';
      border-top: 10px transparent dashed;
      border-left: 10px #fff solid;
      border-bottom: 10px transparent dashed;
      border-right: 10px transparent dashed;
      right: -20px;
      top: 48px;
    }
  }
  
  #qrcode{
    position: relative;
    left: 17px;
    top: 17px;
  }
  .text{
    text-align: center;
    padding:16px 12px;
    &:hover{
      .icon-circle{
        border-radius: 50%;
        background: #fff;
      }
      .icon{
        color:#316fff;
      }
    }
    .icon-circle{
      width: 48px;
      height: 48px;
      text-align: center;
      margin:0 auto 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon{
      font-size: 28px;
    }
    .txt{
      color: #fff;
    }
  }
  .code-box:hover{
    .qrcode-box{
      opacity: 1;
      transition: .8s;
    }
  }
  .tel-con:hover{
    .tel-box{
      opacity: 1;
      transition: .8s;
    }
  }
}
</style>
<style lang="stylus">
/**一分栏  二分栏 选项卡  样式*/
div[data-comp-name="12-12"],div[data-comp-name="1-2"],div[data-comp-name="Tabs"]{
  width: 1200px;
  margin: 0 auto;
}
.backtop
  overflow-y scroll
.flex
  display flex
.lr-flex
  justify-content space-between
.el-message
  min-width 150px
.el-message.is-closable .el-message__content
  padding-right 36px
</style>


