const Routers = require.context('./../modules/', true, /\.router\.js$/)
const wdnRouters = require.context('wdn-pc/src/modules', true, /\.router\.js$/);

let RouterArr = [];
let RouterArr2 = [];

Routers.keys().forEach(key => {
  RouterArr = [
    ...RouterArr,
    ...Routers(key).default
  ]
})
wdnRouters.keys().forEach(key => {
  RouterArr2 = [
    ...RouterArr2,
    ...wdnRouters(key).default
  ]
})
let arr = RouterArr.concat(RouterArr2)
arr.forEach(item => {
    item.meta.highlight = item.path
})
const route = [
  ...RouterArr, // saas定制的页面,以及需要路由覆盖的页面
  ...RouterArr2, // 大平台的页面引入
]
export default route;
