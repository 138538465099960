// import Table from './../layout/table'
export default {
  install: (Vue) => {
    const Templates = require.context('./../layout/', true, /\.vue$/)
    const wdnTemplates = require.context('wdn-pc/src/layout/', true, /\.vue$/)
    // Vue.component(Table.name, Table)
    Templates.keys().forEach(key => {
      const Template = Templates(key).default
      Vue.component(Template.name, Template)
    })
    // 大平台的公共组建的注册。
    wdnTemplates.keys().forEach(key => {
      const Template = wdnTemplates(key).default
      Vue.component(Template.name, Template)
    })
  }
}