

export default API => ({
    order(data) {
        return API({
            url:`/order/v1/open/order/placeOrder/v2`,
            method:'post',
            data
        });
    },
})
