<template>
	<div class="top-account">
		<template v-if="!token">
			<el-dropdown placement="top-end">
				<div class="loginBtn">登录</div>
				<el-dropdown-menu slot="dropdown" class="account-dropdown-menu">
					<el-dropdown-item @click.native="goAdmin">我是资源合作机构</el-dropdown-item>
					<el-dropdown-item divided @click.native="goLogin">我是参培机构</el-dropdown-item>
					<el-dropdown-item divided @click.native="goStudent">我是学员</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</template>

		<template v-else>
			<el-dropdown class="user-dropdown" @visible-change="toggleUser" placement="top-end">
				<div class="user-account">
					<img class="user-img" v-if="userInfo.headImage" :src="userInfo.headImage" alt="" />
					<img class="user-img" v-else src="@assets/userL.png" alt="" />
					<p class="txt">
						{{ branchName || '--' }}
					</p>
					<i :class="[{ 'el-icon-arrow-down': !showUser }, { 'el-icon-arrow-up': showUser }]"></i>
				</div>
				<el-dropdown-menu slot="dropdown" class="account-dropdown-menu">
					<el-dropdown-item>
						<span>{{ userInfo.name || '--' }}</span>
					</el-dropdown-item>
					<el-dropdown-item divided @click.native="goAdmin">
						<span>切换到管理端后台</span>
					</el-dropdown-item>
					<el-dropdown-item divided @click.native="signOut">
						<span>退出登录</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</template>
	</div>
</template>
<script>
export default {
	data() {
		return {
			showUser: false,
			disabled:window.location.hostname.includes('ethrss') && !window.location.hostname.includes('jiafu')
			// branchName: '',
		}
	},
	computed: {
		// 不同机构
		userInfo() {
			return this.$store.state.userInfo
		},
		token() {
			return this.$store.state.token
        },
        branchName() {
            return this.$store.state.branchName
        }
	},
	created() {
		// if (this.token) {
        //     this.getFindUserBranchList()
		// }
	},
	mounted() {},
	methods: {
		goAdmin() {
			let hostname = window.location.hostname.split('.')
			let env = hostname[hostname.length - 3]
			let arr = ['testing1', 'testing2', 'testing3', 'fat', 'uat']

			// if (arr.includes(env)) {
			// 	// 此时不是真正的生产环境
			// 	let url = `https://ethrss-admin.${env}.wdeduc.com`
			// 	window.open(url, '_blank')
			// } else {
			// 	window.open('https://ethrss-admin.wdeduc.com')
			// }
			let url = null 
			const hostname2 = window.location.hostname
			if(hostname2.includes('ethrss') && !hostname2.includes('jiafu')){
				//
				if (arr.includes(env)) {
				// 此时不是真正的生产环境
					url = `https://ethrss-admin.${env}.wdeduc.com`
					window.open(url, '_blank')
				} else {
					window.open('https://ethrss-admin.wdeduc.com')
				}
			}	else if(hostname2.includes('mohrss')) {
				//
				if (arr.includes(env)) {
				// 此时不是真正的生产环境
					url = `https://mohrss-admin.${env}.wdeduc.com`
					window.open(url, '_blank')
				} else {
					window.open('https://mohrss-admin.wdeduc.com')
				}
			}else if(hostname2.includes('jiafu')){
				if (arr.includes(env)) {
				// 此时不是真正的生产环境
					url = `https://jiafu-admin.${env}.ethrss.cn/entrance/login`
					window.open(url, '_blank')
				} else {
					window.open('https://jiafu-admin.ethrss.cn/entrance/login')
				}
			}	
		},
		goLogin() {
			this.$router.push('/login')
		},
		goStudent() {
			let hostname = window.location.hostname.split('.')
			let env = hostname[hostname.length - 3]
			let arr = ['testing3', 'fat', 'uat']
			let url = {} 
			
			const hostname2 = window.location.hostname
			if(hostname2.includes('ethrss') && !hostname2.includes('jiafu')){
				//
				url = {
					testing3: 'http://12639648.channel.testing3.wdeduc.com/login',
					fat: 'https://12655955.channel.fat.wdeduc.com/login',
					uat: 'https://57796486.channel.uat.wdeduc.com/login',
				}
			}	else if(hostname2.includes('mohrss')) {
				//
				url = {
					// testing3: 'http://12639648.channel.testing3.wdeduc.com/login',
					// fat: 'https://12655955.channel.fat.wdeduc.com/login',
					uat: 'https://32156444.channel.uat.wdeduc.com/login',
				}
			}	
			if (arr.includes(env)) {
				// 此时不是真正的生产环境
				window.open(url[env], '_blank')
			} else {
				if((/ethrss.wdeduc.com*/).test(window.location.hostname)){
					window.open('https://43515236.channel.wdeduc.com/login', '_blank')
				}	else if((/jiafu.ethrss.cn*/).test(window.location.hostname)) {
					window.open('https://18188548.jiafu.ethrss.cn/login', '_blank')
				}	
			}
		},
		toggleUser(show) {
			this.showUser = show
		},
		getFindUserBranchList() {
            let branchList = JSON.parse(localStorage.getItem('branchList')) || []
            this.branchName = branchList[0].name
		},
		async signOut() {
			const params = { token: this.token }
            await this.$api.usercenter.postAccounLogout({ params })
            this.$message.success('退出成功')
			this.$store.commit('setToken', '')
			const saasId = localStorage.getItem('saasId')
			sessionStorage.clear()
			localStorage.clear()
			localStorage.setItem('saasId', saasId)
			this.$router.push('/home')
		}
	},
}
</script>
<style lang="stylus" scoped>
@import '../../assets/css/account.styl';
</style>
