<template>
	<div class="jigouNav">
		<div class="nav-box">
			<div class="left-logo">
				<div class="tenant">
					<img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/distributionHomePage/logo2.png" class="logo" alt="" />
				</div>
				<div class="institution">
					<img v-if="branchInfo.logoImg && $store.state.app.isWdBranch" :src="branchInfo.logoImg" class="logo" alt="" />
					<img v-if="!$store.state.app.isWdBranch" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/wdlogo.png" class="logo" alt="" />
					<!-- <span class="name">{{ branchInfo.shortName }}</span> -->
				</div>
			</div>
			<!--  配置导航 -->
			<ul class="nav-contaniner" v-if="navList.length">
				<li class="nav-item" v-for="nav in navList" :key="nav.id" :class="{ active: handleActive(nav.url) }" @click="goPage(nav.url)">
					{{ nav.name }}
				</li>
			</ul>

			<!-- 默认导航 -->
			<ul class="nav-contaniner" v-else>
				<li class="nav-item" :class="{ active: currentPath === '/distributionHomePage' }" @click="goPage('/distributionHomePage')">首页</li>
				<li class="nav-item" :class="{ active: currentPath.includes('/learning') }" @click="goPage('/learning')">学习中心</li>
				<li class="nav-item" :class="{ active: currentPath.includes('/exam') }" @click="goPage('/examList')">考试中心</li>
				<li class="nav-item"  @click="goPage('zhengshu')">证书查询</li>
				<li class="nav-item" v-if="$store.state.app.isWdBranch" :class="{ active: currentPath.includes('/notice') }"  @click="goPage('/notice')">公告公示</li>
			</ul>

			<jigouAccount />
		</div>
	</div>
</template>

<script>
import URL from '../../config/url'
import jigouAccount from './jigouAccount.vue'
export default {
	name: 'jigouNav',
	data() {
		return {
			navList: [],
			branchId: '',
			branchInfo: {
				logoImg: '',
				shortName: '',
			},
		}
	},
	components: {
		jigouAccount,
	},
	computed: {
		currentPath() {
			return this.$route.path
        },
	},
	async mounted() {
		this.getNav()
		await this.getconfiguration()
		// 获取机构logo/简称
		this.showBranchInfo()
	},
	methods: {
		handleActive(path) {
			if (path.includes('/learning')) {
				return this.currentPath.includes('/learning')
			} else if (path.includes('/exam')) {
				return this.currentPath.includes('/exam')
			}
			return this.currentPath === path
		},
		getconfiguration() {
			return this.$api.configure.configurationNew(URL.confUrl).then((res) => {
				if (res.success && res.data) {
					this.branchId = res.data.branchId
				}
			})
		},
		showBranchInfo() {
			this.$api.rensheUsercenter.showBranchInfo({ branchId: this.branchId }).then((res) => {
				if (res.data) {
					this.branchInfo = res.data
				}
			})
		},
		goPage(path) {
      if(path === 'zhengshu'){
          window.open('http://edu.mohrss.gov.cn/zscx.jsp?urltype=tree.TreeTempUrl&wbtreeid=1575')
      }else {
			this.$router.push(path)
      }
		},
		getNav() {
			this.$api.configure.configurationNew(URL.confUrl).then((res) => {
				if (res.data) {
					this.navList = res.data.menuList || []
				}
			})
		}
	},
}
</script>

<style lang="stylus" scoped>
.jigouNav
    display flex
    justify-content center
    width 100%
    height 72px
    background #ffffff
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
    position fixed
    top 0
    left 0
    z-index 999
    .nav-box
        min-width 1200px
        display inline-flex
        margin 0 auto
        align-items:center;
        .left-logo
            display: flex;
            align-items: center;
            .tenant
                .logo
                    height 48px
                    display block
            .institution
                display: flex;
                align-items: center;
                margin 0 24px
                .logo
                    height 40px
                    // max-width 240px
                    // max-height 40px
                    // width 48px
                    // height 48px
                    // margin-right 4px
                    // vertical-align middle
                // .name
                //     margin-right 56px
                //     font-size: 16px;
                //     font-family: PingFangSC-Medium, PingFang SC;
                //     font-weight: 500;
                //     color: #333333;

    .nav-contaniner
        display flex
        flex 1
        // margin-left 52px
        .nav-item
            position relative
            font-size: 16px;
            color: #666666
            cursor pointer
            text-align center
            margin-right 48px
            &.active
                color #316FFF
                &::after
                    content ''
                    position absolute
                    left 50%
                    bottom -10px
                    transform translateX(-50%)
                    width calc(100% - 8px)
                    height: 3px;
                    background: #316FFF;
                    border-radius: 2px;
</style>
