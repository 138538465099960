<template>
	<div class="rensheNav">
		<div class="nav-box">
			<div class="left-logo">
				<img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/logo3.png" class="logo" alt="" />
				<span>人力资源和社会保障部社会保障能力建设中心</span>
			</div>
			<ul class="nav-contaniner">
				<li class="nav-item" :class="{ active: currentPath === '/home' }" @click="goPage('/home')">首页</li>
				<li class="nav-item" :class="[{ active: currentPath === '/projectIntro' }, { noUnderLine: peixunListShow }]">
					<el-dropdown placement="top" @visible-change="handleVisible">
						<span class="el-dropdown-link">培训项目</span>
						<el-dropdown-menu slot="dropdown" class="account-dropdown-menu peixun" :append-to-body="false">
							<el-dropdown-item
								:divided="index !== 0"
								@click.native="handleJumpProjectIntroRoute(item)"
								v-for="(item, index) in peixunList"
								:key="item.code+index"
								:style="highlight(item)"
								>{{ item.name }}</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
				<li class="nav-item" :class="{ active: currentPath === '/institutionsIn' }" @click="goPage('/institutionsIn')">合作机构入驻</li>
				<!-- <li class="nav-item" :class="{ active: currentPath === '/public' }"  @click="goPage('public')">公告公示</li> -->
				<li class="nav-item"  @click="goPage('zhengshu')">证书查询</li>
			</ul>
			<rensheAccount />
		</div>
	</div>
</template>

<script>
import rensheAccount from './rensheAccount.vue'
export default {
	name: 'rensheNav',
	data() {
		return {
			peixunList: [
				{
					name:'早教指导',
					code:'4-10-01-01',
					gongzhong:'4-10-01-01-01',
					id:'zjzd',
				},
				{
					name: '育婴员',
					code: '4-10-01-02',
					id:'yyy',
				},
				{
					name: '小儿推拿',
					code:'4-10-01-02',
					gongzhong:'4-10-01-02-02',
					id:'xetn',
				},
				{
					name: '养老护理员',
					code: '4-10-01-05',
					gongzhong:'4-10-01-05-02',
					id:'ylhly',
				},
				{
					name: '失能失智照护（老年人照护）',
					gongzhong: '4-10-01-05-01',
					code: '4-10-01-05',
					id:'snszzh'
				},
				{
					name: '母婴护理员',
					code: '4-10-01-06',
					id:'myhly',
				},
				{
					name: '产后康复',
					code:'4-10-01-06',
					gongzhong:'4-10-01-06-05',
					id:'chkf',
				},
				{
					name: '公共营养师',
					code:'4-14-02-01',
					gongzhong:'4-14-02-01-02',
					id:'ggyys',
				},
				{
					name: '老年人能力评估师',
					code:'4-14-02-05',
					id:'lnrnlpgs',
				},
				// {
				// 	name: '健康管理师',
				// 	code: '4-14-02-02',
				// },
				// {
				// 	name: '健康照护师',
				// 	code: '4-14-01-02',
				// },
				{
					name: '家政职业经理人',
					code:'4-10-01-11',
					gongzhong:'4-10-01-11-01',
					id:'jzzyjlr'
				},
				// {
				// 	name: '社区养老服务',
				// },
				{
					name: '师资培训项目',
					code: '4-10-01-02',
					spec: 'teacher',
					id:'szpxxm'
				}
			],
			peixunListShow: false,
		}
	},
	components: {
		rensheAccount,
	},
	computed: {
		currentPath() {
			return this.$route.path
		},
	},
	mounted() {},
	methods: {
		handleVisible(val) {
			this.peixunListShow = val
		},
		highlight(item) {
			if (this.$route.query.id && this.$route.query.id == item.id) {
				return {
					color: '#316fff',
				}
			} else {
				return {}
			}
		},
		goPage(path) {
      if(path === 'zhengshu'){
          window.open('http://edu.mohrss.gov.cn/zscx.jsp?urltype=tree.TreeTempUrl&wbtreeid=1575')
      }else {
          this.$router.push(path);
      }
		},
		handleJumpProjectIntroRoute(item) {
			if (item.code) {
				this.$router.push({
					path: '/projectIntro',
					query: {
						code: item.code,
						spec:item.spec,
						gongzhong:item.gongzhong,
						id:item.id
					},
				})
			} else {
				this.$toast('正在建设中，敬请期待…')
			}
		},
	},
}
</script>

<style lang="stylus" scoped>
.account-dropdown-menu
    width 156px
    height 317px
    text-align left
    border-radius 6px
    overflow auto
    .el-dropdown-menu__item
        line-height 20px
        padding 16px
        font-size 16px
        >span
            font-size 14px
            color #333333
        &:hover
            background unset
            color #316FFF
    .el-dropdown-menu__item--divided
        margin 0px
        &::before
            margin 0px
            height 0px
    .popper__arrow
        display none
.peixun
    position absolute !important
    left -45px !important
    top 36px !important
    margin-top 0 !important
    transform-origin center top !important
    /deep/ .popper__arrow
        left 50% !important
        top -6px !important
        transform translateX(-50%) !important
        border-top-width: 0 !important;
        border-bottom-color: #FFF !important;
        &::after
            border-top-width: 0 !important;
            border-bottom-color: #FFF !important;

.rensheNav
    display flex
    justify-content center
    width 100%
    height 72px
    background #ffffff
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
    position fixed
    top 0
    left 0
    z-index 999
    .nav-box
        min-width 1200px
        display inline-flex
        margin 0 auto
        align-items:center;
        .left-logo
            position relative
            display: flex;
            align-items: center;
        .logo
            height 48px
            display block
		span{
			color:#333;
			font-family: PingFangSC-Medium, PingFang SC;
			font-size:20px;
			font-weight:500;
			margin-left 8px;
		}

    .nav-contaniner
        display flex
        flex 1
        margin-left 52px
        .nav-item
            position relative
            font-size: 16px;
            color: #666666
            cursor pointer
            text-align center
            margin-right 48px
            &.active
                color #316FFF
                &::after
                    content ''
                    position absolute
                    left 50%
                    bottom -10px
                    transform translateX(-50%)
                    width calc(100% - 8px)
                    height: 3px;
                    background: #316FFF;
                    border-radius: 2px;
                .el-dropdown-link
                    color #316FFF
            .el-dropdown-link
                font-size: 16px;
                color: #666666
            &.active.noUnderLine
                &::after
                    display none
</style>
