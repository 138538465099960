/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import store from './../store'
import api from './../api'
import isrenshe from '@/utils/judgeDomain'

import routes from './routers'
import conf from '../config/url.js'
Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes,
})
let frontendConfig = null;
const setWebsiteConfig = async () => {
	// 获取配置参数【主题，页面配置】
	await api.configure.configurationNew(conf.confUrl).then((res) => {
		if (res.data) {
			localStorage.setItem('domain', JSON.stringify(res.data.domain) ? JSON.stringify(res.data.domain) : '')
			localStorage.setItem('theme', res.data.theme || '')
			localStorage.setItem('configurationArr', JSON.stringify(res.data.frontendConfig))
			localStorage.setItem('branchId', res.data.branchId || '')
			localStorage.setItem('rid',res.data.solutionInfo.rid)
            store.commit('setTheme', res.data.theme)
			if (res.data.pageLogo) {
				let link = document.querySelector("link[rel*='icon']")
				link.href = res.data.pageLogo
				localStorage.setItem('pageLogo', res.data.pageLogo)
			}
			if (res.data.pageTitle) {
				document.title = res.data.pageTitle
				localStorage.setItem('pageTitle', res.data.pageTitle)
			}
		}
	})
}

const breadArr = []
router.beforeEach(async (to, from, next) => {
	await setWebsiteConfig();
	await getIsWdBranchId();
  	if(['','0',undefined,null,'undefined'].includes(localStorage.getItem('rid'))){
		if(to.name!='unfind'){
			next({
				path:'/unfinds'
			})
		}
	}
	
	let {token,organ,actor,branch,name} = to.query
	console.log('获取路由');
    if(token){
		localStorage.setItem('token',token)
		store.commit('setToken', token)
        localStorage.setItem('organ',organ)
        localStorage.setItem('actor',actor)
        localStorage.setItem('branch',branch)
        localStorage.setItem('branchName',name)
        getAccountUserInfo()
		getUserInfo()

    }else{
        token = localStorage.getItem('token') || ''
    }

	if (from.name === 'projectIntro' && to.name === 'goodsDetail') {
		localStorage.removeItem('rehsheBread')

		let obj = {
			path: from.path,
			query: from.query,
			nameV: '项目介绍',
		}
		breadArr[0] = obj
		localStorage.setItem('rehsheBread', JSON.stringify(breadArr))
	}

	if (from.name === 'distributionHomePage' && to.name === 'goodsDetail') {
		localStorage.removeItem('rehsheBread')
		let obj = {
			path: from.path,
			query: from.query,
			nameV: '首页',
		}
		breadArr[0] = obj
		localStorage.setItem('rehsheBread', JSON.stringify(breadArr))
	}
	if(token){
		if(!localStorage.getItem('rensheH5Domain') && !isrenshe){
			getSaasBranchDomain();
		}
	}
	if (token && to.path === '/login') {
        if (to.query.name) { // 跳转机构学员端商品详情（机构学员端登录状态）
            next(to.query.name + window.location.search)
        } else {
            //避免重复登录
		    next('/')
        }
    }
    
	if (!token && to.meta.login) {
		next({
			path: '/login',
			query: {
				name: to.name,
			},
		})
	} else if (to.path === '/') {
		let homePage = isrenshe ? '/home' : '/distributionHomePage'
		next(homePage)
	} else {
		next()
	}
})

router.afterEach(() => {
	window.scrollTo(0, 0)
})
let h5domainGlobal = '';
async function getSaasBranchDomain(){
	await api.usercenter.getSaasBranchDomain(localStorage.getItem('branchId')).then((res) => {
		if (res.success && res.data && res.data.length) {
			let h5domainGlobal = ''
			res.data.forEach((item) => {
				if (item.branchDomain && item.pcOrH5 === 1) {
					h5domainGlobal = item.branchDomain || '';
					localStorage.setItem('rensheH5Domain',h5domainGlobal)
				}
			})
		}
	})	
}
async function  getIsWdBranchId(){
	if(store.state.app.isWdBranch===true || store.state.app.isWdBranch===false){
		return
	}
	if(localStorage.getItem('branchId')=='' || localStorage.getItem('branchId')==null){
	   return 
	}
	await api.cms.branchIdIsWDBranch({branchId:localStorage.getItem('branchId')}).then(res=>{
		if(res.code==0){
			store.commit('setWdBranch',res.data)
		}
	})
}
function getAccountUserInfo() {
    api.usercenter.getAccountUserInfo({}).then((res) => {
        store.commit('saveUserInfo', res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
    })
}

function getUserInfo() {
    api.usercenter.findUser().then((res) => {
        if (res.code == 0) {
            store.commit('saveSubUserInfo', res.data)
            localStorage.setItem('subUserInfo', JSON.stringify(res.data))
        }
    })
}

export default router
