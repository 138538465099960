import Axios from 'axios'
import URL from '../config/url'
import { Message } from 'element-ui'
const baseURL = URL.API

const service = Axios.create({ withCredentials: false, baseURL })

// 请求拦截器
service.interceptors.request.use(
	(config) => {
		config.headers['source-host'] = 'pc'//同一账号，同一端只能登陆一个
		localStorage.getItem('token') && (config.headers['token'] = localStorage.getItem('token'))
		localStorage.getItem('organ') && (config.headers['organ'] = localStorage.getItem('organ'))
		localStorage.getItem('actor') && (config.headers['actor'] = localStorage.getItem('actor'))
		localStorage.getItem('branch') && (config.headers['branch'] = localStorage.getItem('branch'))
		localStorage.getItem('channel') && (config.headers['channel'] = localStorage.getItem('channel'))//区分管理员、学员
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)

// 响应拦截器
service.interceptors.response.use(
	(config) => {
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)
/* eslint-disable */
let arr = []
let isPoint = false
function loop() {
	setTimeout(() => {
		Message({
			message: arr[0],
			duration: 2000,
			type: 'error',
			onClose: () => {
				arr.shift()
				if (arr.length) {
					loop()
				} else {
					isPoint = false
				}
			},
		})
	}, 2500)
}
function API(config) {
	const { url = '', data = null, params = null, headers = { 'content-type': 'application/json' }, method = 'get', timeout = 30000 } = config || {}
	return new Promise((resolve, reject) => {
		service({
			method, // 请求方式
			url, // 请求路径
			timeout, // 请求超时
			headers, // header体
			data, // body参数
			params, //  URL参数
		})
			.then((res) => {
				if (res.data.code) {
					if (arr.indexOf(res.data.message) == -1) {
						arr.push(res.data.message)
					}
					if (!isPoint) {
						isPoint = true
						loop()
					}
					if (res.data.code === 403) {
						window.location = `//${window.location.host}/login`
						resolve('跳转登录页')
						return
					}

					// 登录信息失效 无token 去登录页
					if (+res.data.code === 303 || +res.data.code === 10000) {
						if (window.location.pathname === '/login') return
						window.location = `//${window.location.host}/login`
						localStorage.clear()
						sessionStorage.clear()
						resolve('跳转登录页')
					}
				}
				resolve({
					...res.data,
					headers: res.headers,
				})
			})
			.catch((err) => {
				reject(err)
				if (err && err.message) {
					if (err.message === 'Network Error') {
						arr.push('网络异常')
						if (!isPoint) {
							isPoint = true
							loop()
						}
					}
					if (err.message.indexOf('timeout') !== -1) {
						arr.push('网络异常')
						if (!isPoint) {
							isPoint = true
							loop()
						}
					}
				}
			})
	})
}
export default API
