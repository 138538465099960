/**
 * @file
 * @author tanghao
 * @date 2019-06-19
 */

import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
//引入
import dynamic from '@wd/lowcode-pc/plugin/store/index.js'; 
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        theme:'',
        pageConfigs: [],
        testPaper: '',
        userInfo:localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')) || {},
        subUserInfo:localStorage.getItem('subUserInfo') && JSON.parse(localStorage.getItem('subUserInfo')) || {},
        areaList:[],
        token: '' || localStorage.getItem('token'),
        branchName:'' || localStorage.getItem('branchName')
    },
    mutations: {
        //主题
        setTheme(state, payload){
            state.theme = payload
        },
        setToken(state, payload){
            state.token = payload
        },
        setBranchName(state, payload){
            state.branchName = payload
        },
        setPageConfigs(state, payload) {
            state.pageConfigs = payload;
        },
        // 账号级别
        saveUserInfo(state, payload) {
            state.userInfo = payload;
        },
        updateUserInfo(state,payload){
            state.userInfo[payload.key] = payload.value;
        },
        // 机构级别
        saveSubUserInfo(state, payload) {
            state.subUserInfo = payload;
        },
        updateSubUserInfo(state,payload){
            state.subUserInfo[payload.key] = payload.value
        },
        // 城市列表
        saveCityTree(state,payload){
            state.areaList = payload
        }
    },
    actions: {
    },
    getters: {

    },
    modules: {
        app,
        pages,
        dynamic
    },
});
